<template>
    <div class="pd20x flex1">
        <addProblem :testList="testList" :visible="openProblem" :currentItem="currentItem" :edit="edit" :sid="currentIndex==0 || !tags.length?0:tags[tabIndex].id" @close="openProblem = false" @update="getProblems"></addProblem>
        <addClass :visible="openClass" :currentItem="currentItem" :edit="edit" :sid="currentIndex==0 || !tags.length?0:tags[tabIndex].id" @close="openClass = false" @update="getCourse"></addClass>
        <editSubject :visible="addVisible" @close="addVisible = false;getTags(subjectList[currentIndex].id);tabIndex = 0" :tags="tags" :parentId="subjectList.length?subjectList[currentIndex].id:null" ></editSubject>
        <div class="h100pct ofA">
            <div class="filter">
                <div>
                    <div class="radioGroup">
                        <div class="radioItem" v-for="(item, index) in subjectList" :key="index" :class="{active: index == currentIndex}" @click="changeType(index)">{{item.name}}</div>
                    </div>
                </div>
                <div>
                </div>
            </div>
            <div class="radio-line" style="position:relative;padding-right: 120px;" v-if="currentIndex != 0">
                <div class="radio-line-item" v-for="(item, index) in tags" :key="index" :class="{active: index == tabIndex}" @click="changeTab(index)">{{item.name}}</div>
                <a-button type="primary" class="addBtn" @click="addVisible = true">编辑科目</a-button>
            </div>
            <div class="filter">
                <div> 
                    <a-select v-model="type" style="width: 120px" @change="handleChange">
                        <a-select-option value="0"> 课程 </a-select-option>
                        <a-select-option value="1"> 题库 </a-select-option>
                    </a-select>
                </div>
                <div>
                    <div style="color:#ff5f4e" class="csP" @click="openClass = true; currentItem = null; edit = false" v-if="currentIndex != 0 && tags.length && type == 0"><a-icon type="plus" />&nbsp;添加课程</div>
                    <div style="color:#ff5f4e" class="csP" @click="openProblem = true; currentItem = null; edit = false" v-if="currentIndex != 0 && tags.length && type == 1"><a-icon type="plus" />&nbsp;添加题库</div>
                </div>
            </div>
            <div class="dragTable" v-if="type == 0">
                <div class="dragHeader">
                    <div class="iconTh"></div>
                    <div class="iconTh"></div>
                    <div class="flex1">课程名</div>
                    <div class="flex1">课程链接</div>
                    <div class="flex1">讲义</div>
                    <div class="operate">操作</div>
                </div>
                <draggable handle=".dragIcon" :list="result" v-if="result.length" @change="log">
                    <div v-for="(item, index) in result" :key="index" class="dragWrap">
                        <div class="dragBody border" v-if="item.parent_id == 0">
                            <div class="iconTh">
                                <i class="dragIcon"></i>
                            </div>
                            <div class="iconTh">
                                <div v-if="item.son.length">
                                    <a-icon type="plus-circle" class="plusIcon" :theme="'filled'" style="color:#ffe867" @click="changePlus(index)" v-if="!item.expend"/>
                                    <a-icon type="minus-circle" class="plusIcon" :theme="'filled'" style="color:#ffe867" @click="changePlus(index)" v-else/>
                                </div>
                                <div v-else>
                                    <a-icon type="minus-circle" class="plusIcon" :theme="'filled'" style="color:#ffe867"/>
                                </div>
                            </div>
                            <div class="flex1">{{item.name}}</div>
                            <div class="flex1"></div>
                            <div class="flex1">
                                <!-- <div v-if="item.parent_id !=0">
                                    <span v-if="item.note_type == 0">通用</span>
                                    <span v-else>{{item.note_content}}</span>
                                </div> -->
                            </div>
                            <div class="operate">
                                <a-icon type="plus-circle" class="plusIcon" @click="openClass = true; currentItem = item;edit = false"/>
                                <i class="z-icon edit" @click="()=>{openClass = true; currentItem = item; edit = true}"></i>
                                <i class="z-icon delete" @click="deleteItem(item.id)"></i>
                            </div>
                        </div>
                        <template v-if="item.son.length && item.expend">
                            <div class="dragBody border" v-for="(son, sindex) in item.son" :key="sindex">
                                <div class="iconTh">
                                    <!-- <i class="dragIcon"></i> -->
                                </div>
                                <div class="iconTh"></div>
                                <div class="flex1">{{son.name}}</div>
                                <div class="flex1" style="color:#70b2e2" :title="son.vedio">{{son.vedio}}</div>
                                <div class="flex1">
                                    <span v-if="son.note_type == 1">通用</span>
                                    <span v-else style="color:#70b2e2" :title="son.note_content">{{son.note_content}}</span>
                                </div>
                                <div class="operate">
                                    <a-icon type="plus-circle" class="plusIcon" style="visibility:hidden" />
                                    <i class="z-icon edit" @click="()=>{openClass = true; currentItem = son; edit = true}"></i>
                                    <i class="z-icon delete" @click="deleteItem(son.id)"></i>
                                </div>
                            </div>
                        </template>
                    </div>
                </draggable>
                <div v-else style="padding:20px 0;text-align:center;background:#fff">
                    暂无数据
                </div>
            </div>
            <div class="dragTable" v-if="type == 1">
                <div class="dragHeader">
                    <div class="iconTh"></div>
                    <div class="iconTh"></div>
                    <div class="flex1">科目章节课程</div>
                    <div class="flex1">试卷链接</div>
                    <div class="operate">操作</div>
                </div>
                <draggable handle=".dragIcon" :list="result" v-if="result.length" @change="log">
                    <div v-for="(item, index) in result" :key="index" class="dragWrap">
                        <div class="dragBody border" v-if="item.parent_id == 0">
                            <div class="iconTh">
                                <i class="dragIcon"></i>
                            </div>
                            <div class="iconTh">
                                <div v-if="item.son.length">
                                    <a-icon type="plus-circle" class="plusIcon" :theme="'filled'" style="color:#ffe867" @click="changePlus(index)" v-if="!item.expend"/>
                                    <a-icon type="minus-circle" class="plusIcon" :theme="'filled'" style="color:#ffe867" @click="changePlus(index)" v-else/>
                                </div>
                                <div v-else>
                                    <a-icon type="minus-circle" class="plusIcon" :theme="'filled'" style="color:#ffe867"/>
                                </div>
                            </div>
                            <div class="flex1">{{item.paper_name}}</div>
                            <div class="flex1"></div>
                            <div class="operate">
                                <a-icon type="plus-circle" class="plusIcon" @click="openProblem = true; currentItem = item; edit = false"/>
                                <i class="z-icon edit" @click="()=>{openProblem = true; currentItem = item; edit = true}"></i>
                                <i class="z-icon delete" @click="deleteItem(item.id)"></i>
                            </div>
                        </div>
                        <template v-if="item.son.length && item.expend">
                            <div class="dragBody border" v-for="(son, sindex) in item.son" :key="sindex">
                                <div class="iconTh">
                                </div>
                                <div class="iconTh"></div>
                                <div class="flex1">{{son.paper_name}}</div>
                                <div class="flex1" style="color:#70b2e2">{{testList.filter(el=>el.id == son.paper_url).length?testList.filter(el=>el.id == son.paper_url)[0].name:''}}</div>
                                <div class="operate">
                                    <a-icon type="plus-circle" class="plusIcon" style="visibility:hidden" />
                                    <i class="z-icon edit" @click="()=>{openProblem = true; currentItem = son; edit = true}"></i>
                                    <i class="z-icon delete" @click="deleteItem(son.id)"></i>
                                </div>
                            </div>
                        </template>
                    </div>
                </draggable>
                <div v-else style="padding:20px 0;text-align:center;background:#fff">
                    暂无数据
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import editSubject from './editSubject'
import addClass from './subject/addClass'
import addProblem from './subject/addProblem'
import * as http from '@/libs/examapi'
import { getTaskList } from '@/libs/api'
export default {
    name: 'subject',
    components:{ editSubject, addClass, addProblem },
    data(){
        return{
            tabIndex: 0,
            currentIndex: 0,
            result: [],
            subjectList:[],
            selectedRowKeys: [],
            areaval: '',
            addVisible:false,
            tags: [],
            openClass: false,
            currentItem: null,
            edit:false,
            type: '0',
            openProblem: false,
            testList: [],
            showProfessional:false
        }
    },
    mounted(){
        this.$nextTick(()=>{
            this.getAlltest().then(()=>{
                this.getCates()
                this.getCourse()
            })
        })
    },
    methods:{
        getAlltest(){
            return new Promise((resolve, reject)=>{
               getTaskList({user_id:this.$store.state.userInfo.id,page:1,size:5000}).then(res=>{
                    if(res.list.length){
                        this.testList = res.list.map(el=>({
                            id: Number(el.id),
                            name: el.title
                        }))
                    }
                    resolve()
                },error=>{
                    reject()
                    console.log(error)
                })
           })  
        },
        changeType(index){
            this.result = []
            this.tabIndex = 0
            this.currentIndex = index
            if(index != 0){
                this.getTags(this.subjectList[this.currentIndex].id).then(res=>{
                    this.type == 0 ? this.getCourse() : this.getProblems()
               })
            }else{
                this.type == 0 ? this.getCourse() : this.getProblems()
            }
        },
        getTags(id){
           return new Promise((resolve,reject)=>{
               http.get_subject({cate_id:id}).then(res=>{
                    if(res.length){
                    this.tags = res
                    }else{
                        this.tags = []
                    }
                    resolve()
                }).catch(error=>{
                    reject
                    console.log(error)
                })
           }) 
        },
        getCourse(){
            this.result = []
            if(this.currentIndex == 0 || this.tags.length){
                http.get_course({sid: this.currentIndex==0?0:this.tags[this.tabIndex].id}).then(res=>{
                    if(res.length){
                        res.forEach(el=>{
                            el.expend = true
                        })
                        this.result = res
                    }else{
                        this.result = []
                    }
                }).catch(error=>{
                    console.log(error)
                })
            }
        },
        getProblems(){
            this.result = []
            if(this.currentIndex == 0 || this.tags.length){
                http.get_problems({sid: this.currentIndex==0?0:this.tags[this.tabIndex].id}).then(res=>{
                    if(res.length){
                        res.forEach(el=>{
                            el.expend = true
                        })
                        this.result = res
                    }else{
                        this.result = []
                    }
                }).catch(error=>{
                    console.log(error)
                })
            }
        },
        getCates(){
            http.get_cate().then(res=>{
                res.forEach(el=>{
                    this.subjectList.push(...el.son)
                })
                this.subjectList = this.subjectList.map(el=>({
                    id: el.id,
                    name: el.name
                }))
                this.subjectList.unshift({id:0, name:'全部'})
            }).catch(error=>{
                console.log(error)
            })
        },
        handleChange(){
            if(this.type == 0){
                this.getCourse()
            }else{
                this.getProblems()
            }
        },
        deleteItem(id){
            let _this = this
             this.$confirm({
                title: '是否确定删除该条信息?',
                centered: true,
                onOk() {
                    if(_this.type == 0){
                        http.del_course({id}).then(res=>{
                            _this.getCourse()
                        }).catch(error=>{
                            console.log(error)
                        })
                    }else{
                        http.del_problems({id}).then(res=>{
                            _this.getProblems()
                        }).catch(error=>{
                            console.log(error)
                        })
                    }
                },
            });
        },
        log(){
            let result = { ids:this.result.map(el=>el.id).join(',')}
            if(this.type == 0){
                http.order_course(result).then(res=>{
                }).catch(error=>{
                    this.$message.error('拖拽失败，请重新操作')
                    this.getData()
                    console.log(error)
                })
            }else{
                http.order_Problems(result).then(res=>{
                }).catch(error=>{
                    this.$message.error('拖拽失败，请重新操作')
                    this.getProblems()
                    console.log(error)
                })
            }
        },
        changePlus(index){
           this.result[index].expend = !this.result[index].expend
        },
        changeTab(index){
            this.tabIndex = index;
            this.type == 0 ? this.getCourse() : this.getProblems()
        }
    }
}
</script>
<style lang="less" scoped>
.addBtn{
    position: absolute;
    right: 20px;
    bottom: 10px;
}
.plusIcon{
    font-size: 20px;
    margin-right: 20px;
    color: #333;
    vertical-align: middle;
    cursor: pointer;
}
.filter{
    background: #fff;
}
.dragWrap{
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 5px;
}
.dragBody.border{
    border-radius: 0;
    margin-bottom: 2px;
}
.radioGroup .radioItem{
    margin-bottom: 10px;
    border-radius: 4px;
}
.radioGroup .radioItem.active{
    background: #ff5f4e;
    border-bottom: 1px;
    color: #fff;
}
</style>